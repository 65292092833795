// ===== Override the messy stuff ===== 

.blog-styles .content {
    padding: 4rem 0;
    // margin: 0;
}

.blog-styles .blog-container {

    line-height: 1.75 !important;
    // font-weight: $font-weight-default !important;

    ul {
        padding: revert;
        list-style-type: revert;
        margin-bottom: 1rem;
    }

}

// ========== End overrides ===========

// Container

.blog-styles .blog-container {

    background: $color-bg;

    & > * {
        margin-left: auto;
        margin-right: auto;
        max-width: 40rem;
    }

    & > p:has( > img) {
        max-width: 100%;
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

}

// Heading area

.blog-styles .blog-container {
    h1.blog-title {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .blog-byline {
        color: $color-text-low-contrast;
        margin-top: 0;
        margin-bottom: 2rem;
    }
}

// Typography

.blog-styles .blog-container {

    // Headings

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        text-transform: revert;
    }

    h1, h2,
    .h1, .h2 {
        color: $smh-color-coch;
    }

    h3, 
    .h3 {
        color: $smh-color-navy;
    }

    h4, h5, h6,
    .h4, .h5, .h6 {
        color: $color-text-low-contrast;
    }


    h1, .h1 { font-size: 2.5rem; }
    h2, .h2 { font-size: 2rem; }
    h3, .h3 { font-size: 1.8rem; }
    h4, .h4 { font-size: 1.5rem; }
    h5, .h5 { font-size: 1.2rem; }
    h6, .h6 { font-size: 1rem; }

    @media (min-width: $breakpoint-medium) {
        h1, .h1 { font-size: 4rem; }
        h2, .h2 { font-size: 3.2rem; }
        h3, .h3 { font-size: 2.5rem; }
        h4, .h4 { font-size: 2.0rem; }
        h5, .h5 { font-size: 1.6rem; }
        h6, .h6 { font-size: 1.2rem; }
    }
    // Links

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
            color: $color-text-low-contrast;
        }
    }
}
