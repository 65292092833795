/* Fonts
 ****************************************************************/

$font-default: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$font-main: 'Inter', $font-default;
$font-heading: 'DM Sans', $font-main;
$font-mono: ui-monospace, "Cascadia Mono", "Segoe UI Mono", "Ubuntu Mono", "Roboto Mono", Menlo, Monaco, Consolas, monospace;

$font-weight-highcontrast: 600;
$font-weight-default: 400;

/* Colors
 ****************************************************************/

// Brand

$smh-color-mustard: #f2b440;
$smh-color-navy: #203140;
$smh-color-coal: #262626;
$smh-color-peach: #da6c43;
$smh-color-coch: #a53b24;
$smh-color-grey:#435c60;

$color-text-inverse: #fff;
$color-text-high-contrast: $smh-color-coal;
$color-text-low-contrast: $smh-color-grey;
$color-text-accent: $smh-color-coch;

$color-bg: #fff;
$color-bg-low-contrast: #eee;
$color-bg-inverse: $smh-color-navy;

$color-detail-high-contrast: #222;
$color-detail-low-contrast: #555;

/* Breakpoint
 ****************************************************************/

$breakpoint-xsmall: 400px;
$breakpoint-small: 550px;
$breakpoint-medium: 750px;
$breakpoint-large: 1000px;
$breakpoint-xlarge: 1200px;