.container {
	display: block;
	margin: 0 auto;
	max-width: 70em;
	padding: 0 1rem;
	@include clearfix;
}

.header {
	background: $color-bg-inverse;
	padding: 1.5rem 0 1rem;

	.header-logo svg {
		@media (min-width: $breakpoint-medium) {
			height: 4.5rem;
		}
		
		height: 3rem;
		display: block;
	}

	&.sticky {
		position: sticky;
		top: 0;
		z-index: 100;
	}
}

.logo {
	float: left;
	font-size: 1.8rem;
	font-weight: $font-weight-default;
	margin: 0;
	line-height: 1;
}

.nav {
	float: right;

	a {
		@extend .logo;
	}
}

.content {
	background: $color-bg;
	padding: 4rem 0 0;
	margin-bottom: 32rem;
	position: relative;
	top: 0;
	left: 0;
	z-index: 1;

	@media (min-width: $breakpoint-medium) {
		padding: 10rem 0 4rem;
		margin-bottom: 25rem;
	}
}

// ——— HOME STYLES ———

.intro,
.projects,
.blog,
.about,
.podcast {
	padding: 0 0 5rem;
}

.intro {

	h1 {
		color: $color-text-low-contrast;
	}

	@media (min-width: $breakpoint-medium) {
		p {
			font-size: 1.75rem;
			max-width: 75%;
			line-height: 1.5;
		}
	}
	
	strong {
		display: block;
		margin-top: 0.5em;
		font-weight: $font-weight-highcontrast;
		color: $smh-color-peach;
		line-height: 1;
	}
}

.projects-list {

	display: grid;
	grid-template-columns: 1fr;
	row-gap: 40px;
	column-gap: 20px;

	@media (min-width: $breakpoint-small) {
		grid-template-columns: 1fr 1fr;
	}
	
	@media (min-width: $breakpoint-medium) {
		grid-template-columns: 1fr 1fr 1fr;
	}
	
	h3 {
		margin-top: 0;
		color: $smh-color-coch;
	}

	
	li {
		display: block;
	}

	a:hover,
	a:focus {
		.img-wrapper {
			&:before {
				opacity: 1;
			}
			&:after {
				opacity: .8;
			}
		}
	}

	.img-wrapper {
		position: relative;

		&:before {
			content: "View Project";
			color: $color-text-inverse;
			font-weight: $font-weight-highcontrast;
			position: absolute;
			display: flex;
			text-align: center;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			opacity: 0;
			-webkit-transition: all .5s ease-in-out;
    		transition: all .5s ease-in-out;
    		-webkit-transition-delay: .25s;
    		transition-delay: .25s;
    		z-index: 2;
		}

		&:after {
			background: $color-bg-inverse;
			content: "";
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			opacity: 0;
			-webkit-transition: all .5s ease-in-out;
    		transition: all .5s ease-in-out;
    		z-index: 1;
		}
	}

	img {
		width: 100%;
	}

}

.project {
	left: 0;
	position: relative;
	top: 0;

	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}

	article ul {
		list-style: square;
		margin-left: 2.5rem;

		li {
			margin-bottom: 24px;
		};
	}

	@media (min-width: $breakpoint-medium) {
		margin-top: -21rem;

		.article-wrapper {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
	}

	.container {
		max-width: 100%;
	}

	article {
		margin-bottom: 3rem;

		@media (min-width: $breakpoint-medium) {
			float: right;
			right: 0;
			width: 40%;
			position: absolute;
			right: 5%;
			margin-top: 3rem;
		}
	}

	aside {
		margin-left: -1rem;
		margin-right: -1rem;

		@media (min-width: $breakpoint-small) {
			@include clearfix;
		}

		@media (min-width: $breakpoint-medium) {
			float: left;
			width: 55%;
		}
	}

	h1 {
		color: $color-text-accent;
		margin: 0;
	}

	li {
		margin-bottom: 0rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	img {
		width: 100%;
	}
}

.project-navigation {
	background: $smh-color-mustard;
	padding: 5rem 0;

	a {
		color: $smh-color-navy;
		display: inline-block;

		&:hover {
			h2:after {
				visibility: visible;
				-webkit-transform: scaleX(1);
				transform: scaleX(1);
			}

			&.prev {
				span {
					left: 7px;
				}
			}

			span {
				&:after {
					left: 7px;
				}

				&:before {
					left: -7px;
				}
			}
		}

		&:nth-of-type(1),
		&:nth-of-type(2) {
			display: table;
			margin: 0 auto;
			text-align: center;

			@media (min-width: $breakpoint-xsmall) {
				display: inline-block;
				margin: 0;
				text-align: left;
			}
		}

		&:nth-of-type(1) {
			@media (min-width: $breakpoint-xsmall) {
				float: left;
			}
		}

		&:nth-of-type(2) {
			margin-top: 3rem;

			@media (min-width: $breakpoint-xsmall) {
				float: right;
				margin-top: 0;
			}
		}

		&.next {
			span {
				&:after {
					content: "\02192";
				}
			}
		}

		&.prev {
			span {
				position: relative;
				left: 0;
				-webkit-transition: all .3s ease-in-out 0s;
				transition: all .3s ease-in-out 0s;

				&:before {
					content: "\02190";
				}
			}
		}
	}

	span {
		&:after,
		&:before {
			position: relative;
			left: 0;
			-webkit-transition: all .3s ease-in-out 0s;
			transition: all .3s ease-in-out 0s;
		}
	}

	h2 {
		margin-top: 0;
		position: relative;

		// animated hover underline
		&:after {
			content: "";
			position: absolute;
			width: 100%;
			height: 1px;
			bottom: -4px;
			left: 0;
			background-color: $color-bg;
			visibility: hidden;
			-webkit-transform: scaleX(0);
			transform: scaleX(0);
			-webkit-transition: all .3s ease-in-out 0s;
			transition: all .3s ease-in-out 0s;
		}
	}
}

.blog, .podcast {

	ul {
		list-style-type: disclosure-closed;
		padding-left: 1em;
		margin-bottom: 1.5rem;
	}

	a {
		text-decoration: underline;

		&:hover {
			text-decoration: none;
			color: $color-text-accent;
		}
	}
}

.podcast {
	.latest-episode {
		padding: 1rem;
		border-radius: 1rem;
		border: 1px solid $color-bg-low-contrast;
		margin-bottom: 2rem;

		.smol-head {
			margin-top: 0;
		}
	}
	.line-clamp {
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}

.about {

	@media (min-width: $breakpoint-medium) {
		p {
			max-width: 66.66666%;
		}
	}
}

.footer {
	background: $color-bg-inverse;
	color: $smh-color-mustard;
	bottom: 0;
	left: 0;
	padding: 5rem 0 5rem;
	position: fixed;
	right: 0;
	z-index: -1;

	section h2 {
		margin-top: 0;
	}

	a {
		color: $color-text-inverse;
	}

	.registered-company {
		color: $smh-color-mustard;
		clear: both;
		font-size: .75em;
		margin: 0;
		padding-top: 2rem;
	}
}

.contact,
.follow {
	@media (min-width: $breakpoint-medium) {
		float: left;
		margin-bottom: 0;
		width: 33.33333%;
	}
}

.contact,
.follow {
	margin-bottom: 3rem;
}
