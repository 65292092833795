body {
	background: $color-bg;
	color: $color-text-high-contrast;
	font-family: $font-main;
	line-height: 1.6;
	font-weight: $font-weight-default;
}

h1,
h2,
h3,
h4 {
	font-family: $font-heading;
	font-weight: $font-weight-highcontrast;
	line-height: 1;
	margin-top: 1.5em;
	margin-bottom: 0.75em;
}

h1 { font-size: 2.25rem; }
h2 { font-size: 2rem; }
h3 { font-size: 1.5rem; }
h4 { font-size: 1rem; }


@media (min-width: $breakpoint-medium) {
	h1 { font-size: 4rem; }
	h2 { font-size: 2.5rem; }
	h3 { font-size: 1.7rem; }
	h4 { font-size: 1rem; }
}

.smol-head {
	font-family: $font-main;
	font-weight: $font-weight-highcontrast;
	font-size: 0.75rem;
	text-transform: uppercase;
	color: $smh-color-grey;
	letter-spacing: 0.125em;
	margin-bottom: 1rem;
}

p {
	margin: 0;
	margin-bottom: 1em;
}

a {
	color: $color-text-high-contrast;
	text-decoration: none;
}

ul {
	margin: 0;
	padding: 0;
	list-style-type: none;	
}

img {
	display: block;
	max-width: 100%;
}

hr {
	background: $color-detail-low-contrast;
	border: 0;
	display: block;
	height: 1px;
	margin: 3rem 0;

	@media (min-width: $breakpoint-medium) {
		margin: 4.5rem 0;
	}
}

abbr[title] {
	border-bottom: 1px dotted $color-detail-high-contrast;
	text-decoration: none;
}

code {
	background: $color-bg;
	border: 1px solid $color-detail-low-contrast;
	border-radius: 3px;
	font-family: $font-mono;
	font-size: 1.5rem;	
	padding: .1rem .4rem;

	var {
		font-style: italic;
	}
}

dl {
	margin: 0 0 3rem;

	dt,
	dd {
		font-family: $font-main;		
	}

	dt {
		font-weight: $font-weight-highcontrast;
	}

	dd {
		font-weight: $font-weight-default;
	}
}

.responsive-table {
	margin-bottom: 3rem;
	
	@media (max-width: $breakpoint-xsmall) {
		display: block;	
		overflow-x: scroll;
		max-width: $breakpoint-xsmall;

		th,
		td {
			min-width: 10rem;
		}
	}
}

table {
	border-collapse: collapse;	
	width: 100%;

	thead {
		td,
		th {
			font-weight: $font-weight-highcontrast;
		}
	}

	tbody > tr:nth-child(odd) > td, 
	tbody > tr:nth-child(odd) > th {
		background: $color-bg-low-contrast;
	}

	th,
	td {
		font-family: $font-main;
		font-weight: $font-weight-default;
		text-align: left;
		padding: .5rem 1rem;
	}
}

blockquote {
	border-left: .6rem solid $color-detail-high-contrast;
	padding-left: 2rem;
	margin: 0 0 3rem;

	p {
		font-style: italic;
	}

	@media (min-width: $breakpoint-medium) {
		padding-left: 3rem;
	}
}

pre {
	margin: 0 0 3rem;
}

.sr-only {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	overflow: hidden !important;
	margin: -1px !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
  }